/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Spin } from 'antd';
import { getResponsiveImage } from '@/componentWebs/NbmImageNew';
import { connect } from 'react-redux';
import dynamic from 'next/dynamic';
import moment from 'moment';
import { getLinkArticle } from '@/utils/utils';
import EncodeUrl from '@/utils/encode';
import publicRuntimeConfig from '@/utils/config';

const OwlCarousel = dynamic(() => import('@/componentWebs/Global/OwlCarousel'), {
  ssr: false,
  loading: () => null,
});

@connect(({ webs: { dataAd } }) => ({
  dataAd,
}))
class Index extends Component {
  state = {
    dataIntroduse: [],
    categories: [],
    activeTab: '',
    loading: false,
    activeTabMove: '',
    itemBanner: {}
  };

  componentDidMount() {
    const { data, dispatch } = this.props;
    this.setState({
      loading: true,
    });
    dispatch({
      type: 'webs/fetchAllArticle',
      payload: {
        filter: {
          status: '1',
          categoriesId: data.id,
          languagesId: '1',
          dataForOne: true
        },
        range: [0, 1],
        attributes: `id,title,shortDescription,image,createDate,source,tag,author,urlSlugs,categoriesId`,
      },
      callback: res => {
        if (res.success) {
          if (res?.result?.list?.length > 0) {
            this.setState({
              itemBanner: (res?.result?.list?.length > 0 && res?.result?.list[0]) || {},
              loading: false,
            });
          }
        }
      },
    });

    dispatch({
      type: 'webs/fetchAllChildrenCategory',
      payload: {
        filter: {
          status: '1',
          languagesId: '1',
          parentId: Number(data.parentId) ? data.parentId : data.id,
        },
      },
      callback: res => {
        if (res.success) {
          if (res.result && res.result.list && res.result.list.length > 0) {
            const categories =
              (res.result.list || []).sort(function (a, b) {
                return a.orderBy - b.orderBy;
              }) || [];
            this.onChangeTab(categories?.length > 0 && categories[0])
            this.setState({
              categories,
              loading: false,
              activeTab:
                (categories && categories.length > 0 && categories[0] && categories[0].id) || '',
            });
          }
        }
      },
    });
  }

  onChangeTab = (item) => {
    const { dispatch } = this.props;
    this.setState({ loading: true });
    dispatch({
      type: 'webs/fetchAllArticle',
      payload: {
        filter: {
          status: '1',
          categoriesId: item.id,
          languagesId: '1',
        },
        range: [0, 5],
        attributes: `id,title,shortDescription,image,createDate,source,tag,author,urlSlugs,categoriesId`,
      },
      callback: res => {
        if (res.success) {
          if (res.result.list.length) {
            this.setState({
              dataIntroduse: (res && res.result && res.result.list) || [],
              loading: false,
              activeTab: item.id,
            });
          }
        }
      },
    });
  }

  render() {
    const { data } = this.props;
    const { dataIntroduse, categories, activeTab, loading, activeTabMove, itemBanner } = this.state;
    // console.log("itemBanner: ", dataIntroduse);
    const newDataIntroduse = dataIntroduse || [];
    const template =
      data.templateLayouts && data.templateLayouts.templates && data.templateLayouts.templates[0];
    const imageResize =
      template && template.templateLayoutTemplates && template.templateLayoutTemplates.imagesResize;
    const newResize = [
      {
        desktop: {
          width: 1251,
          height: 0
        },
        tablet: {
          width: 700,
          height: 0
        },
        mobile: {
          width: 375,
          height: 0
        },

      }
    ]


    // console.log("newDataIntroduse ádasdasdasd: ", newDataIntroduse);
    return (
      // How we work
      <React.Fragment>
        {/* Khuyen mai  */}
        <section className="khuyenmai ">
          <div className="container">
            <div className="khuyenmai__wrapper">
              <div className="khuyenmai__header hidden-m-t flex-b-c">
                <div className="khuyenmai__header-title flex-a-c">
                  <img src="/static/vietnamTickets/images/008-gift.svg" alt="gift" className='lazyload' />
                  <span>{data.name}</span>
                </div>
                <ul className="khuyenmai__header-list flex-a-c">
                  {categories &&
                    categories.length > 0 &&
                    categories.map(item => (
                      <li
                        key={item.id}
                        onClick={() => {
                          if (item.id !== activeTab)
                            this.onChangeTab(item)
                        }}
                        onMouseMove={() => this.setState({ activeTabMove: item.id })}
                        onMouseLeave={() => this.setState({ activeTabMove: '' })}
                      >
                        <a className={activeTab === item.id ? 'active' : ''}>
                          <img
                            className='lazyload'
                            width='auto' height="auto"
                            src={
                              activeTab === item.id || activeTabMove === item.id
                                ? (item.image &&
                                  item.image[1] &&
                                  getResponsiveImage(item.image[1].file, imageResize)) ||
                                ''
                                : (item.image &&
                                  item.image[0] &&
                                  getResponsiveImage(item.image[0].file, imageResize)) ||
                                ''
                            }
                            alt={item.name}
                          />
                          <span>{item.name}</span>
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
              <Spin spinning={loading}>
                {itemBanner &&
                  itemBanner.image &&
                  itemBanner.image.length > 0 &&
                  itemBanner.image[0] && (
                    <div
                      className="khuyenmai_img hidden-m-t"
                      onClick={() =>
                      (window.location.href = `/${EncodeUrl(itemBanner.categories.name)}/${itemBanner.urlSlugs
                        }/${itemBanner.id}`)
                      }
                    >
                      <img
                        className='lazyload'
                        src={getResponsiveImage(
                          itemBanner.image && itemBanner.image[0] && itemBanner.image[0].file,
                          newResize
                        )}
                        // src={`${publicRuntimeConfig.IMAGE_SERVER_NEW}${
                        //   publicRuntimeConfig.IMAGE_PROJECT
                        // }${itemBanner.image && itemBanner.image[0] && itemBanner.image[0].file}`}
                        style={{ cursor: 'pointer' }}
                        // src="/static/vietnamTickets/images/NoPath - Copy (7).png"
                        alt={itemBanner.title}
                      />
                    </div>
                  )}
                {newDataIntroduse && newDataIntroduse.length > 0 && (
                  <div className="khuyenmai__products">
                    <OwlCarousel
                      className="section-khachHang-owlCarousel"
                      key={activeTab}
                      responsive={{
                        0: {
                          nav: false,
                          items: 1,
                          margin: 14,
                          dots: true,
                        },
                        425: {
                          nav: false,
                          items: 1,
                          margin: 14,
                          dots: true,
                        },
                        768: {
                          nav: true,
                          items: 1,
                          margin: 20,
                          dots: false,
                        },
                        1000: {
                          nav: true,
                          items: 1,
                          margin: 20,
                          dots: false,
                        },
                      }}
                      items={1}
                      margin={20}
                      dots={false}
                      // autoplay
                      // autoplayHoverPause
                      // autoplayTimeout={3000}
                      slideBy={1}
                    // loop
                    >
                      {newDataIntroduse.map(item => (
                        <div className="khuyenmai__box" key={item.id}>
                          <div
                            className="khuyenmai__box__main"
                            onClick={() =>
                            (window.location.href = `/${EncodeUrl(item.categories.name)}/${item.urlSlugs
                              }/${item.id}`)
                            }
                            style={{
                              backgroundImage: `url("${item.image &&
                                item.image[0] &&
                                getResponsiveImage(item.image[0].file, imageResize)}")`,
                              cursor: 'pointer',
                            }}
                          >
                            {/* <img src="/static/vietnamTickets/images/NoPath - Copy (5).png" alt="" class="khuyenmai__box__img"> */}
                            <div className="khuyenmai__box__detail hidden-m-t">
                              <div className="khuyenmai__box__detail-wrapper">
                                <div className="khuyenmai__box-date">
                                  <i className="far fa-clock" />
                                  <span>{moment(item.createDate).format('DD/MM/YYYY')}</span>
                                </div>
                                <a
                                  href={`/${EncodeUrl(item.categories.name)}/${item.urlSlugs}/${item.id
                                    }`}
                                  title={item.title}
                                  className="khuyenmai__box-title"
                                >
                                  {item.title}
                                </a>
                                <div className="khuyenmai__box-desCription">
                                  {item.shortDescription}
                                </div>
                              </div>
                              <button
                                onClick={() =>
                                (window.location.href = `/${EncodeUrl(item.categories.name)}/${item.urlSlugs
                                  }/${item.id}`)
                                }
                                type="button"
                                className="khuyenmai__box-btn btn"
                              >
                                Xem ngay
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                )}
              </Spin>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Index;
